import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"

import Layout from "../components/layout/Layout"
import PostList from "../components/postList"
import Pagination from "../components/Pagination"
import Meta from "../components/meta"

export default class IndexPage extends React.Component {
  render() {
    const { data, pageContext } = this.props
    const { nodes: posts } = data.allMdx
    return (
      <Layout>
        <Helmet title="じゃっくそん's Place"></Helmet>
        <Meta isRoot={true} title="じゃっくそん's Place" />
        <PostList posts={posts} />
        <Pagination pageContext={pageContext} pathPrefix="/" />
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      skip: $skip
      limit: $limit
      filter: { frontmatter: { type: { eq: null } } }
    ) {
      nodes {
        id
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "YYYY.MM.DD")
          title
          description
          thumbnail {
            childImageSharp {
              fluid(maxWidth: 1920, webpQuality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          categories
          tags
        }
      }
    }
  }
`
